span {
    content: attr(data-title) !important;
}

.barCompleted {
    background-color: #7367f0;
    width: 80%;
}

th{
    font-weight: normal;
    vertical-align: middle;
}